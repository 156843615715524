.App {
  align-items: center;
  justify-content: center;
}
.center {
  /* text-align: center; */
  display: block;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 80%;
  max-width: 1300px;
  /* min-width: 500px; */
}

.add-new {
  align-self: flex-end;
}

@media screen and (max-width: 800px) {
  .center {
    width: 100%;
  }
}

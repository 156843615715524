.detail {
  .paper {
    width: "100%";
    border: solid;
    border-radius: 15px;
    padding: 20px;
    border-width: 0.1px;
    border-color: white;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    margin-bottom: 10px;
  }

  input {
    margin-top: 5px;
    width: 190px;
    padding-left: 10px;
    padding-block: 5px;
    border-radius: 5px;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
      rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
    border-color: white;
    border: none !important;
    &:focus {
      outline: none; /* Varsayılan odak çerçevesini gizlemek için */
      border-color: blue;
      box-shadow: 0 0 5px blue;
    }
  }

  .warning {
    display: flex;
    justify-content: center;
    border: 0.5px solid rgb(255, 229, 229);
    padding-inline: 10px;
    padding-block: 5px;
    align-items: center;
    background-color: rgb(255, 229, 229);
    border-radius: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .divider {
    height: 0.5px;
    background-color: #bfbfbf;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .button-71 {
    background-color: #0078d0;
    border: 0;
    border-radius: 56px;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: system-ui, -apple-system, system-ui, "Segoe UI", Roboto, Ubuntu,
      "Helvetica Neue", sans-serif;
    font-size: 18px;
    font-weight: 600;
    outline: 0;
    padding: 5px 24px;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: all 0.3s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }

  .button-71:before {
    background-color: initial;
    background-image: linear-gradient(#fff 0, rgba(255, 255, 255, 0) 100%);
    border-radius: 125px;
    content: "";
    height: 50%;
    left: 4%;
    opacity: 0.5;
    position: absolute;
    top: 0;
    transition: all 0.3s;
    width: 92%;
  }

  .button-71:hover {
    box-shadow: rgba(255, 255, 255, 0.2) 0 3px 15px inset,
      rgba(0, 0, 0, 0.1) 0 3px 5px, rgba(0, 0, 0, 0.1) 0 10px 13px;
    transform: scale(1.05);
  }

  @media (min-width: 768px) {
    .button-71 {
      padding: 8px 24px;
    }
  }

  @media (max-width: 576px) {
    .paper {
      margin-bottom: 100px;
    }
    .col-8 {
      flex: 0 0 auto;
      width: auto;
    }
  }

  @media (max-width: 766px) {
    .paper {
      margin-bottom: 100px;
      margin-top: 10px;
    }
  }
}

.basic-single-long {
  width: 380px !important;
}

.malls-container {
  max-width: 1000px;
  display: flex;
  margin-inline: auto;
  margin-block: 50px;
  flex-direction: column;
  .row {
    width: -webkit-fill-available;
  }
  //   .card {
  //     height: 120px;
  //   }
}

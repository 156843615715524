.table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  border: 1px solid #ddd; /* Add border color */
  border-radius: 10px; /* Add border radius */
}

.table th,
.table td {
  border: 1px solid #ddd; /* Add border color */
  padding: 10px;
  text-align: left;
  button {
    border-radius: 5px;
    padding-inline: 7px;
    color: white;
    background-color: #da3831;
    font-weight: 600;
    border: none;
  }
}

.table th {
  background-color: #f2f2f2; /* Add background color to header */
}

.table tbody tr:hover {
  background-color: #f5f5f5; /* Add hover effect */
}

.active > .page-link,
.page-link.active {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: #da3831;
  border-color: #da3831;
}

.pagination {
  --bs-pagination-color: #da3831;
  --bs-pagination-hover-color: #da3831;
}

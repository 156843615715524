.footer {
  align-items: center;
  background-color: #171717;
  display: flex;
  width: 100%;
  .container {
    background-color: #171717 !important;
    border: none !important;
    // min-height: 250px;
  }
}

img {
  max-width: 100%;
  height: auto;
}

@media (max-width: 990px) {
  .container {
    text-align: center;
    p {
      padding-right: 0px !important;
    }
    .col-lg-2 {
      margin-top: 15px;
    }
  }
}

.support {
  text-align: center;

  .input-container {
    margin-top: 20px;
    width: 400px;
    margin-left: auto;
    margin-right: auto;

    .search {
      background-color: white;
      color: black;
      font-weight: 600;
      border-color: black;
      width: 400px;
      height: 50px;
      border-radius: 3px;
      margin-top: 15px;
      margin-bottom: 50px;
    }

    .card {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-inline: 15px;
      align-items: center;
      margin-bottom: 30px;
      height: 80px;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
      .title {
        text-align: left;
        margin-top: 20px;
        font-weight: 700;
        letter-spacing: 1px;
      }
      .api-type {
        margin-left: 5px;
        margin-top: 20px;
        font-size: 12px;
        color: gray;
        font-weight: 600;
      }
      .sub-title {
        margin-top: -15px;
        text-align: left;
        letter-spacing: 1px;
        font-weight: 600;
        color: rgb(120, 120, 120);
      }
      .cancel-button {
        cursor: pointer;
      }
    }

    .code-field {
      margin-top: 25px;
      margin-bottom: 120px;
      .complete-button {
        width: 400px;
        border-radius: 30px;
        font-weight: 600;
        padding-block: 10px;
        height: 50px;
        margin-bottom: 25px;
      }
    }
  }
}

.table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
}

.table th,
.table td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: left;
}

.table th {
  background-color: #f2f2f2;
}

.table tbody tr:hover {
  background-color: #f5f5f5;
}

.active > .page-link,
.page-link.active {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: rgb(209, 32, 32) !important;
  border-color: rgb(209, 32, 32) !important;
}

.pagination {
  --bs-pagination-color: rgb(209, 32, 32) !important;
  --bs-pagination-hover-color: rgb(209, 32, 32) !important;
}

.received-button {
  background-color: rgb(209, 32, 32) !important;
  color: white;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  font-weight: bold;
}

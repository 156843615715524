.horizontal {
  overflow-x: scroll;
  .part {
    cursor: pointer;
    margin-inline: 10px;
    font-weight: 600;
    font-size: 16px;
    min-width: 110px;
    text-decoration: none;
    color: black;
  }
  .rounded-pill {
    color: white;
    background-color: #da3831;
    font-weight: 600;
    font-size: 16px;
    min-width: 117px;
  }
}

// homepage.scss

.menu-container {
  display: flex;
  flex-direction: column;
  background-color: rgb(226, 226, 226);
  border-radius: 15px;
  max-width: 700px;
  justify-content: space-around;
  padding-inline: 50px;
  padding-block: 50px;
  margin-top: 20px;
  margin-bottom: 200px;
  margin-inline: auto;
  .box {
    background-color: white;
    padding-block: 30px;
    display: flex;
    width: 300px;
    border: solid rgb(207, 207, 207) 0.5px;
    border-radius: 15px;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s ease-in-out;
    text-align: center;
    cursor: pointer;
    &:hover {
      transform: scale(1.05);
    }
    // .button {
    //   background-color: #da3831;
    //   border: none;
    //   border-radius: 30px;
    // }
  }
}

.search-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 20px;
  padding: 0 20px;
  max-width: 700px;
  margin-inline: auto;

  input {
    width: 90%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: white;
  }

  button {
    background-color: #da3831;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 600;
  }
}

.result-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding: 0 20px;
  max-width: 700px;
  margin-inline: auto;
}

.table-container {
  border: #f5f5f5;
  border-width: 0.5px;
  padding-top: 10px;
  border-top-right-radius: 50px;
  border-top-left-radius: 50px;
  background-color: #f5f5f5;
  margin-bottom: 200px;
  display: inherit !important;
  /* min-height: 600px; */
}
.table {
  margin-bottom: 0px;
  box-shadow: 0px 1px 10px rgba(220, 220, 220, 0.25);
}
.table > tbody {
  vertical-align: inherit;
  background-color: white;
}

.table > thead > th {
  padding-bottom: 15px;
}

.horizontal::-webkit-scrollbar {
  display: none;
}

.mobile-container {
  display: none;
}

@media screen and (max-width: 550px) {
  .horizontal {
    padding-left: 20px;
    .part {
      margin-inline: 7px;
      font-weight: 600;
      font-size: 14px;
      min-width: 110px;
    }
    .rounded-pill {
      font-size: 14px;
    }
  }
  .table-container {
    display: none !important;
  }
  .mobile-container {
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: #ffffff;
    margin-bottom: 50px;
    flex-direction: column;
    .mobile-box {
      padding-inline: 15px;
      align-self: center;
      width: 95%;
      background-color: rgb(255, 255, 255);
      margin-bottom: 20px;
      min-height: 80px;
      border: 1px solid #ffffff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-shadow: 0px 0px 10px rgba(16, 24, 23, 0.049);
      border-radius: 10px;
      .box-heading {
        flex-direction: row;
        margin-bottom: -5px;
      }
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #ffffff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}

// loginpage.scss

.login {
  height: 100vh;
  background: linear-gradient(to right, #e74c3c, #d66054);

  .text-center {
    margin-bottom: 2rem;
  }

  h3 {
    color: white;
  }

  .form-group {
    margin-bottom: 1rem;
  }

  .form-control {
    width: 100%;
    margin-bottom: 10px;
  }

  .btn-login {
    border: none;
    width: 100%; /* Make the button full width */
    font-weight: 600;
    margin-top: 50px;
  }
}

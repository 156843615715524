.header-link {
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  padding-right: 20px;
}

@media screen and (max-width: 550px) {
  .navbar {
    display: none;
  }
}
@media screen and (max-width: 992px) {
  .header-link {
    margin-bottom: 5px;
  }
}

.mobile-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-inline: 15px;
  position: sticky;
  top: 0;
  background-color: white;
  padding-block: 10px;
  z-index: 2;
  .btn {
    padding: 0px;
    border: none;
    --bs-btn-active-bg: white;
    --bs-btn-hover-bg: white;
    --bs-btn-bg: white;
  }
  .dropdown-toggle::after {
    display: none;
  }
}
